import { Component } from '@angular/core';

import * as Keycloak_ from 'keycloak-js';
import { KeycloakService } from '../services/keycloak.service';

export const Keycloak = Keycloak_;


@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss'],
})
export class HomePage {

    subject: string;
    username: string;
    tokenExpires: string;
    tokenRefreshExpires: string;
    token: string;
    private idToken: string;

    constructor(
        private keycloakService: KeycloakService
    ) {

    }

    ionViewDidEnter() {
        // this.keycloakService.init();
        if (this.keycloakService.isAuthenticated()) {
            this.keycloakService.userProfile().subscribe(
                value => {
                    // this.subject = this.keycloakInstance.subject;
                    this.username = value.username;
                    // this.tokenExpires = new Date(this.keycloakInstance.tokenParsed.exp * 1000).toLocaleString();
                    // this.tokenRefreshExpires = new Date(this.keycloakInstance.refreshTokenParsed.exp * 1000).toLocaleString();
                    // this.token = JSON.stringify(this.keycloakInstance.tokenParsed, null, '  ')
                    // this.idToken = JSON.stringify(this.keycloakInstance.idTokenParsed, null, '  ');
                }
            )
        }

    }

    login() {
        this.keycloakService.login()
    }

}
