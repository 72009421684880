import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { initializer } from './app-init';
import { KeycloakService } from './services/keycloak.service';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        BrowserTab,
        Deeplinks,
        KeycloakService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        // {provide: APP_INITIALIZER, useFactory: initializer, multi: true, deps: [BrowserTab, Deeplinks, KeycloakService]},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
