import { Component, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { KeycloakService } from './services/keycloak.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/core';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private keycloak: KeycloakService,
        private router: Router,
        private zone: NgZone
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.keycloak.init();
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
        App.addListener('appUrlOpen', (data: any) => {
            this.zone.run(() => {
                // Example url: https://orders.fingerprintsoft.co/home
                // slug = /home
                const slug = data.url.split(".co").pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });
    }
}
