import { Injectable } from '@angular/core';

import * as Keycloak_ from 'keycloak-js';
import { KeycloakProfile } from 'keycloak-js';
import { Platform } from '@ionic/angular';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HttpClient } from '@angular/common/http';
import { HomePage } from '../home/home.page';
import { from, Observable } from 'rxjs';

export const Keycloak = Keycloak_;

export interface KeyValueStr {
    [key: string]: string;
}

export interface AuthToken {
    access_token: string;
    expires_in: number;
    id_token: string;
    'not-before-policy': number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;

    [key: string]: any;
}

@Injectable({
    providedIn: 'root',
})
export class KeycloakService {

    keycloakInstance: Keycloak_.KeycloakInstance
    private _userProfile: Keycloak_.KeycloakProfile;

    constructor(
        private platform: Platform,
        private browserTab: BrowserTab,
        private deeplinks: Deeplinks,
        private http: HttpClient,
    ) {
        this.keycloakInstance = Keycloak({
            url: 'https://orders.fingerprintsoft.co/auth',
            realm: 'orders.fingerprintsoft.co',
            clientId: 'orders.fingerprintsoft.co',
        });
        // this.keycloakInstance.onAuthSuccess = () => this.updateState();
        // this.keycloakInstance.onAuthRefreshSuccess = () => this.updateState();
        // this.keycloakInstance.onAuthLogout = () => this.updateState();
    }

    init() {
        this.keycloakInstance.init({
            adapter: 'cordova-native',
            // responseMode: 'query',
            // onLoad: 'check-sso',
            redirectUri: 'https://orders.fingerprintsoft.co/home',
        }).then(value => {
            console.log('initialised')
        })
            .catch(reason => this.error())

        this.deeplinks.route({
            '/home': HomePage,
        }).subscribe(match => {
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data
            console.log(`Successfully matched route ${match}`);
            if (match.$link) {
                console.log('There is a $link');
            } else {
                console.log('There is no a $link');
            }
            this.setToken(match.$link);

        }, nomatch => {
            // nomatch.$link - the full link data
            if (nomatch.$link) {
                console.log('There is a $link');
            } else {
                console.log('There is no a $link');
            }
            this.setToken(nomatch.$link);
            console.error('Got a deeplink that didn\'t match', nomatch);
        });

    }

    error() {
        console.log('In error')
    }

    setToken(link) {
        console.log(`In update state ${link}`)
        if (link) {
            const {path, fragment, host, scheme, url} = link;
            console.log(`path: ${path}`)
            console.log(`fragment: ${fragment}`)
            console.log(`host: ${host}`)
            console.log(`scheme: ${scheme}`)
            console.log(`url: ${url}`)
            if (this.containsCode(fragment)) {
                const extractCode = this.extractCode(fragment);
                console.log(`extractCode.code ${extractCode.code}`)

                const tokenUrl = `${this.keycloakInstance.authServerUrl}/realms/${this.keycloakInstance.realm}/protocol/openid-connect/token`

                let params = 'code=' + extractCode.code + '&grant_type=authorization_code';
                let req = new XMLHttpRequest();
                req.open('POST', url, true);
                req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                params += '&client_id=' + encodeURIComponent(this.keycloakInstance.clientId);
                params += '&redirect_uri=' + 'https://orders.fingerprintsoft.co/home';

                req.withCredentials = true;

                req.onreadystatechange = function() {
                    if (req.readyState == 4) {
                        if (req.status == 200) {

                            const tokenResponse = JSON.parse(req.responseText);
                            console.log(`Access Token: ${tokenResponse['access_token']}`)
                            console.log(`Refresh Token: ${tokenResponse['refresh_token']}`)
                            console.log(`Id Token: ${tokenResponse['id_token']}`)
                            // authSuccess(tokenResponse['access_token'], tokenResponse['refresh_token'], tokenResponse['id_token'], kc.flow === 'standard');
                            // scheduleCheckIframe();
                        } else {
                            // kc.onAuthError && kc.onAuthError();
                            // promise && promise.setError();
                        }
                    }
                };

                req.send(params);
                /**
                 if (oauth.pkceCodeVerifier) {
                    params += '&code_verifier=' + oauth.pkceCodeVerifier;
                }

                 */
                // let accessTokenParams = new HttpParams()
                //     .set('grant_type', 'authorization_code')
                //     .set('code', extractCode.code)
                //     .set('client_id', encodeURIComponent(this.keycloakInstance.clientId))
                //     .set('redirect_uri', 'http://orders.fingerprintsoft.co/home');
                // const secret = this.keycloakInstance.clientSecret;
                // if (secret) {
                //     accessTokenParams = accessTokenParams
                //         .set('client_secret', encodeURIComponent(secret));
                // }
                // const headers = new HttpHeaders()
                //     .set('Content-Type', 'application/x-www-form-urlencoded');
                //
                // const clientSecret = this.keycloakInstance.clientSecret;
                // const clientId = this.keycloakInstance.clientId;
                // if (clientId && clientSecret) {
                //     headers.set('Authorization', 'Basic ' + btoa(clientId + ':' + clientSecret));
                // }
                //
                // this.http.post<AuthToken>(tokenUrl, accessTokenParams, {headers}).subscribe(value => {
                //         if (value) {
                //
                            // TODO: implement timeout handle
                            // if (this.keycloakInstance.tokenTimeoutHandle) {
                            //     clearTimeout(this.keycloakInstance.tokenTimeoutHandle);
                            //     this.keycloakInstance.tokenTimeoutHandle = null;
                            // }

                            // if (value.refresh_token) {
                            //     this.keycloakInstance.refreshToken = value.refresh_token;
                            //     this.keycloakInstance.refreshTokenParsed = this.decodeToken(value.refresh_token);
                            // } else {
                            //     this.keycloakInstance.refreshToken = undefined;
                            //     this.keycloakInstance.refreshTokenParsed = undefined;
                            // }
                            //
                            // if (value.id_token) {
                            //     this.keycloakInstance.idToken = value.id_token;
                            //     this.keycloakInstance.idTokenParsed = this.decodeToken(value.id_token);
                            // } else {
                            //     this.keycloakInstance.idToken = undefined;
                            //     this.keycloakInstance.idTokenParsed = undefined;
                            // }

                            // if (value.access_token) {
                            //     this.keycloakInstance.token = value.access_token;
                            //     this.keycloakInstance.tokenParsed = this.decodeToken(value.access_token);
                            //     this.keycloakInstance.sessionId = this.keycloakInstance.tokenParsed.session_state;
                            //     this.keycloakInstance.authenticated = true;
                            //     this.keycloakInstance.subject = this.keycloakInstance.tokenParsed.sub;
                            //     this.keycloakInstance.realmAccess = this.keycloakInstance.tokenParsed.realm_access;
                            //     this.keycloakInstance.resourceAccess = this.keycloakInstance.tokenParsed.resource_access;
                            //
                            //     // if (timeLocal) {
                            //     //     this.keycloakInstance.timeSkew = Math.floor(timeLocal / 1000) - this.keycloakInstance.tokenParsed.iat;
                            //     // }
                            //
                            //     if (this.keycloakInstance.timeSkew != null) {
                            //         // logInfo('[KEYCLOAK] Estimated time difference between browser and server is ' + this.keycloakInstance.timeSkew + ' seconds');
                            //
                            //         if (this.keycloakInstance.onTokenExpired) {
                            //             let expiresIn = (this.keycloakInstance.tokenParsed['exp'] - (new Date().getTime() / 1000) + this.keycloakInstance.timeSkew) * 1000;
                            //             // logInfo('[KEYCLOAK] Token expires in ' + Math.round(expiresIn / 1000) + ' s');
                            //             if (expiresIn <= 0) {
                            //                 this.keycloakInstance.onTokenExpired();
                            //             } else {
                            //                 // TODO: implement timeout handle
                            //                 // this.keycloakInstance.tokenTimeoutHandle = setTimeout(this.keycloakInstance.onTokenExpired, expiresIn);
                            //             }
                            //         }
                            //     }
                            // } else {
                            //     this.keycloakInstance.token = undefined;
                            //     this.keycloakInstance.tokenParsed = undefined;
                            //     this.keycloakInstance.subject = undefined;
                            //     this.keycloakInstance.realmAccess = undefined;
                            //     this.keycloakInstance.resourceAccess = undefined;
                            //
                            //     this.keycloakInstance.authenticated = false;
                            // }

                        // } else {
                        //     this.keycloakInstance.refreshToken = undefined;
                        //     this.keycloakInstance.refreshTokenParsed = undefined;
                        //
                        //     this.keycloakInstance.idToken = undefined;
                        //     this.keycloakInstance.idTokenParsed = undefined;
                        //
                        //     this.keycloakInstance.token = undefined;
                        //     this.keycloakInstance.tokenParsed = undefined;
                        //     this.keycloakInstance.subject = undefined;
                        //     this.keycloakInstance.realmAccess = undefined;
                        //     this.keycloakInstance.resourceAccess = undefined;
                        //
                        //     this.keycloakInstance.authenticated = false;
                        // }
                        // console.log(`The user is authenticated: ${this.keycloakInstance.authenticated}`)
                    // },
                    // error1 => {
                    //     console.log("Got an error");
                    //     console.log(`${error1}`);
                    // },
                // )
            }


        }
        // if (this.keycloakInstance.authenticated) {
        //     this.subject = this.keycloakInstance.subject;
        //     this.username = this.keycloakInstance.idTokenParsed['preferred_username'];
        //     this.tokenExpires = new Date(this.keycloakInstance.tokenParsed.exp * 1000).toLocaleString();
        //     this.tokenRefreshExpires = new Date(this.keycloakInstance.refreshTokenParsed.exp * 1000).toLocaleString();
        //     this.token = JSON.stringify(this.keycloakInstance.tokenParsed, null, '  ')
        //     this.idToken = JSON.stringify(this.keycloakInstance.idTokenParsed, null, '  ');
        // }

    }

    private extractCode(url: string) {
        const hashes = url.slice(url.indexOf('?') + 1).split('&');
        const paramsObj = hashes.reduce((params, hash) => {
            const [key, val] = hash.split('=');
            return Object.assign(params, {[key]: decodeURIComponent(val)});
        }, {} as KeyValueStr);
        if ((!paramsObj) || (!paramsObj.code)) {
            return;
        }
        paramsObj.code = (paramsObj.code as string).split('#')[0];
        return paramsObj;
    }

    private containsCode(fragment: string) {
        if (!fragment) {
            return false;
        }
        return fragment.indexOf('code') > -1;
    }

    login() {
        const url = this.keycloakInstance.createLoginUrl({
            redirectUri: 'https://orders.fingerprintsoft.co/home',
        });
        this.browserTab.openUrl(url);
    }

    isAuthenticated() {
        return this.keycloakInstance.authenticated;
    }

    userProfile(): Observable<KeycloakProfile> {
        return from(this.keycloakInstance.loadUserProfile());
    }

    decodeToken(str) {
        str = str.split('.')[1];

        str = str.replace('/-/g', '+');
        str = str.replace('/_/g', '/');
        switch (str.length % 4) {
            case 0:
                break;
            case 2:
                str += '==';
                break;
            case 3:
                str += '=';
                break;
            default:
                throw 'Invalid token';
        }

        str = (str + '===').slice(0, str.length + (str.length % 4));
        str = str.replace(/-/g, '+').replace(/_/g, '/');

        str = decodeURIComponent(escape(atob(str)));

        str = JSON.parse(str);
        return str;
    }


}
